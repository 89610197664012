import React from "react";
import Jumbotron from "../component/Jumbotron"
import Container from '@material-ui/core/Container';



function Home() {
  return (
    <div>
    
    <Container maxWidth="md">
    <Jumbotron>
    </Jumbotron>
    </Container>
      

    </div>
  );
}


export default Home;
